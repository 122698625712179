import { formatDate } from '@angular/common';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../environments/environment';




@Injectable({
  providedIn: 'root'
})
export class SocketService {


  postId: any;
  CAJAS:any[] = [];
  fecha: any;
  resultado: any;
  date: Date | undefined;
  LogsBitacoraCB:any = []
  private URL_API= environment.Api
  constructor(private http: HttpClient,private cookieService: CookieService )  {

  }

   LoginPost(accion:any){

    return this.http.post(this.URL_API+'/login', { action: "login",Token:accion.password,Username:accion.userName,TokenTemp:this.cookieService.get('Token') });
   }

   logout (){
    return this.http.post(this.URL_API+'/login', { action: "logout",TokenTemp:this.cookieService.get('Token') });
   }
   ResetPost(Value:any){
   
    return this.http.post(this.URL_API+'/reset', {newToken:Value.password,Username:this.cookieService.get('Usercap') })
   }


Delete(Value:any){

  return this.http.post(this.URL_API+'/deleteItem', {id: Number(Value)  , TokenTemp: this.cookieService.get('Token')})

}
SaveQuestion(Value:any){

  return this.http.post(this.URL_API+'/pushLogCbot', {Question: Value, TokenTemp: this.cookieService.get('Token')})

}
Received(Value:any){

  return this.http.post(this.URL_API+'/received', {id: Number(Value)  , TokenTemp: this.cookieService.get('Token')})

}

   RestBox(Value:any){
  Value.cajas.forEach((box:any) => {
   this.CAJAS.push({Id:box})

}); 


date: Date;
if(Value.periodo !== 'Indefinido'){

  this.date = new Date(Value.Date_retiro);
  this.date.setDate( this.date.getDate() + Value.periodo );
} else {
  this.date = Value.periodo;
}

    return this.http.post(this.URL_API+'/items', {
      TokenTemp: this.cookieService.get('Token'),
      Title: "",
      Cliente: "",
      iden: this.CAJAS,
      Fechadeprestamo: formatDate(Value.Date_retiro, 'yyyy-MM-dd HH:mm:ss', 'en-US'),
      Fechadedevoluci_x00f3_n: String(this.date) ,
      Estatus: "Solicitado",
      Modalidaddeentrega: Value.retiro,
      Detallesdelamodalidaddeentrega: Value.descripcion,
      Solicitante:Value.PersonaAutorizada,
      Persona_retirar:Value.nombre || Value.PersonaAutorizada,
      Cedula:Value.cedula,
      Correo_Solicitante: Value.correo
    });
      
   }


}
